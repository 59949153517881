import './styles/reset.css';
import './styles/utilities.css';
import './styles/main.css';
import Home from './pages/Home';

function App() {
  return (
    <Home />
  );
}

export default App;
