import { useRef } from 'react';
import { Link } from 'react-router-dom';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import '../styles/nav.css';

function Navbar() {
	const hamburger = useRef();
	const toggleHamburgerAndShowNavLinks = () => {
		hamburger.current.classList.toggle('menu-active');
	};

	return (
		<header className="header">
			<div className="container">
				<div className="navbar">
					<Link to="/" className="logo">
						<img src="assets/Logo-NGW.png" alt="logo" />
					</Link>
					<button
						className="hamburger"
						ref={hamburger}
						onClick={toggleHamburgerAndShowNavLinks}
					>
						<span className="inner"></span>
					</button>
					<ul className="nav__items">
						{/* <li className="nav__item">
							<span>
								Offers
								<FontAwesomeIcon icon={faChevronDown} />
							</span>
							<ul className="inner__nav__items">
								<li className="inner__nav__item">
									<Link to="/">Strategic design</Link>
								</li>
								<li className="inner__nav__item">
									<Link to="/">Mobile product launch</Link>
								</li>
								<li className="inner__nav__item">
									<Link to="/">Connected Objects</Link>
								</li>
							</ul>
						</li> */}
						{/* <li className="nav__item">
							<span>
								Expertise
								<FontAwesomeIcon icon={faChevronDown} />
							</span>
							<ul className="inner__nav__items">
								<li className="inner__nav__item">
									<Link to="/">Products</Link>
								</li>
								<li className="inner__nav__item">
									<Link to="/">Technologies</Link>
								</li>
								<li className="inner__nav__item">
									<Link to="/">Methodology</Link>
								</li>
							</ul>
						</li> */}
						<li className="nav__item">
							<span>
								<a href="#clients">Clients</a>
							</span>
						</li>
						{/* <li className="nav__item">
							<span>
								<Link to="/">Teams</Link>
							</span>
						</li> */}
						{/* <li className="nav__item">
							<span>
								Blog
								<FontAwesomeIcon icon={faChevronDown} />
							</span>
							<ul className="inner__nav__items">
								<li className="inner__nav__item">
									<Link to="/">Business</Link>
								</li>
								<li className="inner__nav__item">
									<Link to="/">Tech</Link>
								</li>
							</ul>
						</li> */}
						{/* <li className="nav__item">
							<span>
								<Link to="/">Jobs</Link>
							</span>
						</li> */}
						<li className="nav__item">
							<span>
								<a href="#footer">Contact</a>
							</span>
						</li>
					</ul>
				</div>
			</div>
		</header>
	);
}

export default Navbar;
