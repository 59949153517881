function HighLights() {
	return (
		<section className="highlights-section mb-4">
			<div className="container container--narrow">
				<div className="highlights">
					<div className="highlight">
						<div className="h3">30</div>
						<p>Dévelopeurs, Graphistes, Motion Designers, Product Owners, QA</p>
					</div>
					<div className="highlight">
						<div className="h3">+1</div>
						<p>million d'utilisateurs sur les app créées par NGWare</p>
					</div>
					<div className="highlight">
						<div className="h3">3</div>
						<p>Apps "featured" dans l'AppStore</p>
					</div>
				</div>
			</div>
		</section>
	);
}

export default HighLights;
