import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faYoutube, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

function Footer() {
	return (
		<footer className="footer" id="footer">
			<div className="container">
				<div className="grid mb-3">
					<div className="grid-item">
						<p>Concretisez vos projets</p>
						<a href="mailto:contact@ngware.fr" className="btn btn--primary">
							Parlons de vos projets
						</a>
					</div>
					<div className="grid-item flow">
						<h3>Contact</h3>
						<address>61 rue de Lyon, 75012 Paris</address>
						<p>
							<a className="hover-underline" href="mailto:contact@ngware.fr">
								contact@ngware.fr
							</a>
						</p>
						<p>
							<a className="hover-underline" href="tel:+33 (0)6 27 39 22 27">
								+33 (0)6 27 39 22 27
							</a>
						</p>
					</div>
					<div className="grid-item flow">
						<h3>Sitemap</h3>
						<ul className="secondary-navigation">
							<li>
								<a href="#clients">Clients</a>
							</li>
							<li>
								<a className="hover-underline" href="mailto:contact@ngware.fr">Contact</a>
							</li>
						</ul>
						<ul><li></li></ul>
						<ul><li></li></ul>
						<ul><li></li></ul>
					</div>
					<div className="grid-item">
						<h3>Suivez-nous</h3>
						<ul className="social__items">
							<li className="social__item">
								<a
									href="https://www.linkedin.com/company/ngware/"
									aria-label="Connect with us on linkedin"
									title="Connect with us on linkedin"
								>
									<FontAwesomeIcon icon={faLinkedinIn} />
								</a>
							</li>
							{/*<li className="social__item">
								<a
									href="https://www.twitter.com"
									aria-label="Follow us on twitter"
									title="Follow us on twitter"
								>
									<FontAwesomeIcon icon={faTwitter} />
								</a>
							</li>
							<li className="social__item">
								<a
									href="https://www.youtube.com"
									aria-label="Follow us on youtube"
									title="Follow us on youtube"
								>
									<FontAwesomeIcon icon={faYoutube} />
								</a>
							</li>*/}
						</ul>
					</div>
				</div>
			</div>
			<div className="copyright">Réalisation NGWARE. Copyright © 2022</div>
		</footer>
	);
}

export default Footer;
