import { Link } from 'react-router-dom';

function Hero() {
	return (
		<>
			<section className="hero mb-2">
				<img src="assets/001.jpg" alt="" />
			</section>
			<section className="hero-content mb-6">
				<div className="container flow">
					<header className="mb-3">
						<h1 className="heading">
							CRÉEZ L’APPLICATION QUE <span>TOUT LE MONDE</span> UTILISE
						</h1>
						<a href="mailto:contact@ngware.fr" className="btn btn--primary">
							Parlons de votre projet
						</a>
					</header>
					<div className="split">
						<div className="left">
							<h2>À propos de nous</h2>
						</div>
						<div className="right">
							<div className="card flow">
								<h3>Mobile First</h3>
								<p>
									Depuis plus de 8 ans notre équipe accompagne des dizaines de clients
									Grand Comptes et Startups afin de répondre à leur besoins dans le développement de leurs Business.
									Que ce soit pour des applications BtoB, BtoC ou pour de l'évènementiel, notre
									expérience nous permet de vous guider au mieux pour obtenir très rapidement l'App dont vous avez besoin!
								</p>
								<a className="btn btn--secondary" href="#expertise">En savoir plus</a>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default Hero;
