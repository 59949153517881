import Achievements from '../components/home/Achievements';
import Expertise from '../components/home/Expertise';
import Footer from '../components/home/Footer';
import Hero from '../components/home/Hero';
import HighLights from '../components/home/HighLights';
import Navbar from '../components/Navbar';

function Home() {
	return (
		<>
			<Navbar />
			<main className="home">
				<Hero />
				<HighLights />
				<Expertise />
				<Achievements />
			</main>
			<Footer />
		</>
	);
}

export default Home;
