import React from 'react';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/bundle';
import '../../styles/carousel.css';

// import required modules
import { Pagination, Autoplay } from 'swiper';

function Carousel() {
	const images = [
		'assets/002.jpeg',
		'assets/003.png',
		'assets/004.jpg',
		'assets/005.jpeg',
		'assets/006.jpeg',
		'assets/007.jpeg',
		'assets/008.jpeg',
		'assets/009.jpeg',
		'assets/010.jpeg',
		'assets/011.jpeg',
		'assets/012.jpeg',
		'assets/013.jpeg',
		'assets/014.jpeg',
		'assets/015.jpeg',
		'assets/016.jpeg',
		'assets/017.jpeg',
		'assets/018.jpeg',
	];
	return (
		<Swiper
			pagination={{
				clickable: true,
			}}
			autoplay={{
				delay: 2500,
				disableOnInteraction: false,
			}}
			modules={[Pagination, Autoplay]}
			className="mySwiper mb-3"
		>
			{React.Children.toArray(
				images.map((image) => (
					<SwiperSlide>
						<img src={image} alt="" />
					</SwiperSlide>
				))
			)}
		</Swiper>
	);
}

export default Carousel;
