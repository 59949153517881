import React from 'react';

import Carousel from './Carousel';

import logos from '../../constants/logos';

function Achievements() {
	return (
		<>
			<section className="achievements-section">
				<div className="container flow">
					<header>
						<p>Nos Clients</p>
						<h2>Quelques réalisations</h2>
					</header>
					<Carousel />

					<header>
						<p>Motion Design</p>
						<h2>Showreel 2022</h2>
					</header>
					<div className="achievements-video">
						<video src="assets/019.mp4" autoPlay muted loop controls></video>
					</div>
					<div className="recommendation flow container container--narrow">
					 <br />
					 <br />
						<h3>
							<span>98%</span> de nos clients satisfaits
						</h3>
						<br />
 					 <br />
					</div>
				</div>
			</section>
			<section className="achievements-section mb-3" id="clients">
				<div className="container">
					<div className="card">
						<div className="card__header">
							<h3>+ de 40 projets réalisés</h3>
							<p>Pour des Startups et des Grands Comptes</p>
						</div>
						<div className="card__images grid">
							{React.Children.toArray(
								logos.map((logo) => <img className="card__image" src={logo} alt="" />)
							)}
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default Achievements;
