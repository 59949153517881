import React from 'react';

function ExpertiseItem({ item }) {
	return (
		<div className="expertise" id="expertise">
			<h3 className="mb-2" data-title={item.title}>
				{item.title}
			</h3>
			<ul>{React.Children.toArray(item.expertise.map((exp) => <li>{exp}</li>))}</ul>
		</div>
	);
}

export default ExpertiseItem;
