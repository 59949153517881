import React from 'react';
import { Link } from 'react-router-dom';
import ExpertiseItem from './ExpertiseItem';

function Expertise() {
	const expertises = [

		{
			title: 'Design',
			expertise: [
				'Prototypes / MVP',
				'Chartes Graphique',
				'Développement Produit',
				'Motion Design',
				'Modélisation 3D VR AR'
			],
		},
		{
			title: 'Code',
			expertise: ['Swift', 'SwiftUI', 'ReactJS', 'React Native', 'Unity 3DVRAR / C#'],
		},
		{
			title: 'Production',
			expertise: [
				'Mise en production',
				'Gestion des Backlogs',
				'Amélioration continue',
				'Lean Developpement',
				'Analyse des données et pilotage (Analytics)',
			],
		},
	];
	return (
		<section className="expertise-section mb-6">
			<div className="container container--narrow">
				<header className="flow mb-2">
					<h2>Expertise</h2>
					<p>Vos applications clé en main</p>
					<a className="btn btn--secondary" href="#expertise">Nos projets</a>
				</header>
				<div className="expertises">
					{React.Children.toArray(
						expertises.map((item) => <ExpertiseItem item={item} />)
					)}
				</div>
			</div>
		</section>
	);
}

export default Expertise;
