const logos = [
  'assets/logos/1.png',
  'assets/logos/2.png',
  'assets/logos/3.png',
  'assets/logos/4.png',
  'assets/logos/5.png',
  'assets/logos/6.png',
  'assets/logos/7.png',
  'assets/logos/8.png',
  'assets/logos/9.png',
  'assets/logos/10.png',
  'assets/logos/11.png',
  'assets/logos/12.png',
  'assets/logos/13.png',
  'assets/logos/14.png',
  'assets/logos/15.png',
  'assets/logos/16.png',
  'assets/logos/17.png',
  'assets/logos/18.png',
  'assets/logos/19.png',
  'assets/logos/20.png',
  'assets/logos/21.png',
  'assets/logos/22.png',
  'assets/logos/23.png',
  'assets/logos/24.png',
  'assets/logos/25.png',
  'assets/logos/26.png',
  'assets/logos/27.png',
  'assets/logos/28.png',
  'assets/logos/29.png',
  'assets/logos/30.png',
  'assets/logos/31.png',
  'assets/logos/32.png',
  'assets/logos/33.png',
  'assets/logos/34.png',
  'assets/logos/35.png',
  'assets/logos/36.png',
  'assets/logos/37.png',
];

export default logos;